
import { defineComponent } from "vue";

type Location = {
  id: number;
  hoursOffset: number;
  city: string;
  country: string;
};

const locations: Location[] = [
  {
    id: 1,
    hoursOffset: 0,
    city: "London",
    country: "United Kingdom",
  },
  {
    id: 2,
    hoursOffset: 1,
    city: "Berlin",
    country: "Germany",
  },
  {
    id: 3,
    hoursOffset: 2,
    city: "Bucharest",
    country: "Romania",
  },
  {
    id: 4,
    hoursOffset: -1,
    city: "Reykjavik",
    country: "Iceland",
  },
  {
    id: 5,
    hoursOffset: -6,
    city: "Mexico city",
    country: "Mexico",
  },
  {
    id: 6,
    hoursOffset: 9,
    city: "Tokyo",
    country: "Japan",
  },
  {
    id: 7,
    hoursOffset: 10,
    city: "Sydney",
    country: "Australia",
  },
  {
    id: 8,
    hoursOffset: 5,
    city: "Mumbai",
    country: "India",
  },
];

const currentHour = new Date().getUTCHours();

export default defineComponent({
  data() {
    return {
      locations,
      selectedLocationIds: [] as number[],
      newLocationVModel: undefined as number | undefined,
    };
  },

  computed: {
    selectedLocations(): (Location | undefined)[] {
      return this.selectedLocationIds.map((id) =>
        locations.find((location) => location.id === id)
      );
    },
  },

  methods: {
    setNewLocationVModel(value: string | undefined) {
      console.log(value);

      const parsedValue = Number(value);

      if (!Number.isNaN(parsedValue)) {
        this.newLocationVModel = parsedValue;

        this.selectedLocationIds.push(parsedValue);

        this.newLocationVModel = undefined;
      }
    },

    getHour(value: number, offset: number) {
      return (value + offset + 24) % 24;
    },
  },
});
